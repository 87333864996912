.footer {
  flex: 0 0 auto;
  p {
    text-align: center;
    margin: 10px 0 0 0;
    a {
      text-decoration: none;
    }
  }
}
.copyright {
  // position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: darken($color: $color-primary, $amount: 5);
  padding: 0 25px;
  flex: 0 0 auto;
  z-index: 2;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
