.main-header {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
  .logo {
    display: none;
    width: 140px;
    height: 50px;
    overflow: hidden;
    margin: 30px auto 15px;

    @include media-breakpoint-down(xs) {
      display: inline;
      width: 50px;
      height: auto;
      margin: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .alarmContainer {
    position: relative;
    .icon-alarm {
      display: flex;
    }
    .badge {
      position: absolute;
      top: 0;
    }
    a:hover {
      text-decoration: none;
    }
  }

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 25px 0 15px;
    @include breakpoint(small) {
      margin: 25px 20px 15px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
.mobile-header {
  display: none !important;

  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex !important;
    padding: 0 20px;
  }

  .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    width: 45px;
    height: 47px;
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    background: rgb(44, 48, 68);
    @include breakpoint(large) {
      display: none;
    }
    &:active {
      padding: 0;
    }
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 25px;
      color: white;
      transition: all 0.3s;
    }
  }
  &.collapsed-sidebar {
    @include breakpoint(large) {
      width: calc(100% - 70px);
    }
  }
  .logo {
    display: none;
    width: 140px;
    height: 50px;
    overflow: hidden;
    margin: 30px auto 15px;

    @include media-breakpoint-down(sm) {
      display: inline;
      width: 10rem;
      height: auto;
      margin: auto;
      padding: 0 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 25px 0 15px;
    @include breakpoint(small) {
      margin: 25px 20px 15px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
