.main-container {
  .form-control {
    border-radius: 2px;
  }
  .card {
    border-radius: 2px;
  }
  .btn {
    border-radius: 5px;
    &.btn-minwidth {
      min-width: 120px;
    }
  }
  .custom-switch {
    height: 100%;
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      & ~ label {
        width: 30px;
        height: 15px;
        @include themify() {
          background: darken(themed('white'), 10%);
        }
        border-radius: 50px;
        position: relative;
        transition: all ease 0.3s;
        cursor: pointer;
        &::before {
          position: absolute;
          border-radius: 50%;
          right: 0;
          transition: all ease 0.3s;
          top: 0;
          height: 100%;
          width: 15px;
          content: '';
          @include themify() {
            background: darken(themed('white'), 50%);
          }
        }
      }
      &:checked ~ label {
        &::before {
          right: calc(100% - 15px);
          @include themify() {
            background: themed('blue01'); // box-shadow: 0 0 8px 2px rgba(themed('blue01'), .5);
          }
        }
      }
    }
  } // #endregion
  // #region custom dark table bootstrap
  .table-dark {
    @include themify() {
      color: themed('white');
      background-color: themed('primary');
    }
    th,
    td,
    thead th {
      @include themify() {
        border-color: rgba(themed('white'), 0.08);
      }
    }
  } // #endregion
  // #region custom file upload
  .custom-file-upload {
    width: 100%;
    input[type='file'] {
      visibility: hidden;
      position: absolute;
    }
    .custom-file-label {
      border-radius: 2px;
      position: relative;
      width: 100%;
      display: block;
      @include textOverflow();
      padding-left: 120px;
      &::after {
        width: 100px;
        text-align: center;
        border-left: none;
        margin-right: auto;
        content: attr(data-content);
      }
    }
  } // #endregion
  // #region custom-chk
  .custom-chk {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    & ~ label {
      position: relative; // padding-right: 16px;
      &::before {
        position: absolute;
        right: -1.25rem;
        @include themify() {
          background: darken(themed('white'), 10%);
        }
        border-radius: 3px;
        top: 4px;
        content: '';
        width: 13px;
        height: 13px;
      }
    }
    &:checked ~ label::before {
      @include themify() {
        background: themed('blue01')
          url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
          no-repeat center;
      }
      background-size: 9px;
    }
  } // #endregion
  // #region custom-rdo
  .custom-rdo {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    & ~ label {
      position: relative; // padding-right: 16px;
      &::before {
        position: absolute;
        right: -1.25rem;
        @include themify() {
          background: darken(themed('white'), 10%);
        }
        border-radius: 50%;
        top: 4px;
        content: '';
        width: 13px;
        height: 13px;
      }
    }
    &:checked ~ label::before {
      @include themify() {
        background: themed('blue01')
          url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
          no-repeat center;
      }
      background-size: 7px;
    }
  } // #endregion
  // #region pagination
  .pagination {
    .page-item.active .page-link {
      z-index: 1;
      color: #fff;
      background-color: $color-primary;
      border-color: $color-primary;
    }
    .page-item {
      margin-top: 5px;
      &.disabled {
        .page-link {
          color: $gray-600;
        }
      }
      &:first-child {
        .page-link {
          border-left: none;
        }
      }
      .page-link {
        color: $sidebar-background;
        &:focus {
          // z-index: 0;
          outline: 0;
          box-shadow: none;
        }
        &:hover {
          // z-index:0;
        }
      }
    }
  } // #endregion
}

// #region custom datepicker
.datepicker-plot-area {
  border-radius: 3px;
  font: 13px IRANSans !important;
  @include themify() {
    box-shadow: 0 5px 10px darken(themed('white'), 10%);
  }
  margin-top: 2px;
  .datepicker-navigator {
    .pwt-btn-next,
    .pwt-btn-prev {
      color: transparent;
      @include themify() {
        background: themed('primary');
      }
      border-radius: 3px;
      position: relative;
      transition: background ease 0.3s;
      &::before {
        width: 100%;
        height: 100%;
        font-family: PartFontIcon, sans-serif;
        @include themify() {
          color: themed('white');
        }
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        font-size: 15px;
      }
      &:hover {
        @include themify() {
          background: rgba(themed('primary'), 0.8);
        }
      }
    }
    .pwt-btn-next {
      &::before {
        content: '\f104';
      }
    }
    .pwt-btn-prev {
      &::before {
        content: '\f106';
      }
    }
    .pwt-btn-switch {
      font-weight: 500;
      line-height: 32px;
    }
  }
  .datepicker-day-view {
    .month-grid-box {
      .header {
        .header-row-cell {
          font-weight: 500;
        }
      }
    }
    .table-days {
      td {
        span {
          transition: all ease 0.1s;
          border-radius: 3px;
          &:hover {
            @include themify() {
              background: themed('primary');
            }
          }
        }
        &.selected {
          span {
            text-shadow: none;
            @include themify() {
              background: themed('secondary') !important;
            }
          }
        }
      }
    }
  }
  .datepicker-month-view {
    .month-item {
      border-radius: 3px;
      transition: all ease 0.1s;
      &.selected {
        text-shadow: none;
        @include themify() {
          background: themed('secondary') !important;
        }
      }
      &:hover {
        @include themify() {
          background: themed('primary');
        }
      }
    }
  }
  .datepicker-year-view {
    .year-item {
      border-radius: 3px;
      transition: all ease 0.1s;
      &.selected {
        text-shadow: none;
        @include themify() {
          background: themed('secondary') !important;
        }
      }
      &:hover {
        @include themify() {
          background: themed('primary');
        }
      }
    }
  }
  .toolbox {
    .pwt-btn-calendar,
    .pwt-btn-today {
      @include themify() {
        background: themed('primary');
      }
      border-radius: 3px;
      @include themify() {
        color: themed('white');
      }
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.1s;
      &:hover {
        @include themify() {
          background: rgba(themed('primary'), 0.8);
        }
      }
    }
  }
}

//////////custom tooltip
.tooltip {
  font-family: inherit;
}

////////// Custom link style
a,
a:link {
  color: $gold;
  &:hover {
    color: darken($gold, 10%);
  }
}

///////////////// Custom Checkbox
.custom-control-label {
  padding-right: 1.25rem;
}

.custom-control,
.form-check {
  padding-left: 1.25rem !important;
  padding-right: 0 !important;
}

// #endregion
// custom material checkbox
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type='checkbox'] {
  + span {
    position: relative;
    padding-right: 25px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    user-select: none;
  }
  + span:not(.lever):before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid $sidebar-background;
    border-radius: 1px;
    margin-top: 3px;
    transition: 0.2s;
  }
  &:not(.filled-in) + span:not(.lever):after {
    border: 0;
    transform: scale(0);
  }
  &:not(:checked):disabled + span:not(.lever):before {
    border: none;
    background-color: yellow;
  }
  &.tabbed:focus + span:not(.lever):after {
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }
}

[type='checkbox']:checked {
  + span:not(.lever):before {
    content: '';
    top: -4px; //   left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid $sidebar-background;
    border-bottom: 2px solid $sidebar-background;
    transform: rotate(40deg);
    backface-visibility: hidden;
    transform-origin: 100% 100%;
  }
  &:disabled + span:before {
    border-right: 2px solid $sidebar-background;
    border-bottom: 2px solid $sidebar-background;
  }
}

[type='checkbox'].filled-in {
  + span:not(.lever):after {
    border-radius: 2px;
  }
  + span:not(.lever):before,
  + span:not(.lever):after {
    content: '';
    right: 0;
    position: absolute;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
      top 0.2s 0.1s, left 0.2s 0.1s;
    z-index: 1;
  }
  &:not(:checked) + span:not(.lever):before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    right: 10px;
    top: 10px;
    transform: rotateZ(37deg);
    transform-origin: 100% 100%;
  }
  &:not(:checked) + span:not(.lever):after {
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px solid $sidebar-background;
    top: 0;
    z-index: 0;
    border-radius: 3px;
  }
  &:checked {
    + span:not(.lever):before {
      top: -1px;
      right: 10px;
      width: 7px;
      height: 12px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid $gray-100;
      border-bottom: 2px solid $gray-100;
      transform: rotateZ(37deg);
      transform-origin: 100% 100%;
    }
    + span:not(.lever):after {
      top: 0;
      height: 18px;
      width: 18px;
      border: 2px solid $sidebar-background;
      background-color: $sidebar-background;
      z-index: 0;
      border-radius: 3px;
    }
  }
  &.tabbed:focus + span:not(.lever):after {
    border-radius: 2px;
    border-color: $sidebar-background;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.tabbed:checked:focus + span:not(.lever):after {
    border-radius: 2px;
    background-color: $sidebar-background;
    border-color: $sidebar-background;
  }
  &:disabled:not(:checked) + span:not(.lever):before {
    background-color: transparent;
    border: 2px solid transparent;
  }
  &:disabled:not(:checked) + span:not(.lever):after {
    border-color: #e1e7ed;
    background-color: #e1e7ed;
  }
  &:disabled:checked + span:not(.lever):before {
    background-color: transparent;
  }
  &:disabled:checked + span:not(.lever):after {
    background-color: lighten($sidebar-background, 15%);
    border-color: lighten($sidebar-background, 15%);
  }
  &:disabled:checked + span {
    cursor: not-allowed;
    color: #999;
  }
  &:disabled:not(:checked) + span {
    cursor: not-allowed;
    color: #999;
  }
}

// }
// End
