@import 'variables';
@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nxd9ng');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nxd9ng#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?nxd9ng') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?nxd9ng') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?nxd9ng##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-at {
  &:before {
    content: $icon-at;
    color: $silver;
  }
}
.icon-calendar1 {
  &:before {
    content: $icon-calendar1;
    color: $silver;
  }
}
.icon-mobile-phone {
  &:before {
    content: $icon-mobile-phone;
    color: $silver;
  }
}
.icon-Shape {
  &:before {
    content: $icon-Shape;
  }
}
.icon-tag {
  &:before {
    content: $icon-tag;
    color: #7e86af;
  }
}
.icon-new-1 {
  &:before {
    content: $icon-new-1;
    color: #ffb300;
  }
}
.icon-new-2 {
  &:before {
    content: $icon-new-2;
    color: #f9f9fb;
  }
}
.icon-mark {
  &:before {
    content: $icon-mark;
    color: #808091;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
    color: $primary;
  }
}
.icon-refund {
  &:before {
    content: $icon-refund;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-diamond {
  &:before {
    content: $icon-diamond;
    color: $primary;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
    color: $silver;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
    color: #808091;
  }
}
.icon-storage {
  &:before {
    content: $icon-storage;
    color: #808091;
  }
}
.icon-support {
  &:before {
    content: $icon-support;
    color: #808091;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
    color: #b5b5be;
  }
}
.icon-www {
  &:before {
    content: $icon-www;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
    color: #808091;
  }
}
.icon-order {
  &:before {
    content: $icon-order;
    color: $primary;
  }
}
.icon-password {
  &:before {
    content: $icon-password;
    color: #b5b5be;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
    color: #dddfe9;
  }
}
.icon-plus-1 {
  &:before {
    content: $icon-plus-1;
    color: $silver;
  }
}
.icon-plus-2 {
  &:before {
    content: $icon-plus-2;
    color: #808091;
  }
}
.icon-plus-3 {
  &:before {
    content: $icon-plus-3;
    color: #dddfe9;
  }
}
.icon-product {
  &:before {
    content: $icon-product;
    color: #ff3d57;
  }
}
.icon-protest {
  &:before {
    content: $icon-protest;
  }
}
.icon-bill-1 {
  &:before {
    content: $icon-bill-1;
    color: #09b66d;
  }
}
.icon-bill-2 {
  &:before {
    content: $icon-bill-2;
  }
}
.icon-bin {
  &:before {
    content: $icon-bin;
    color: $silver;
  }
}
.icon-gear {
  &:before {
    content: $icon-gear;
  }
}
.icon-dashboard-1 {
  &:before {
    content: $icon-dashboard-1;
  }
}
.icon-dashboard-2 {
  &:before {
    content: $icon-dashboard-2;
  }
}
.icon-dashboard-3 {
  &:before {
    content: $icon-dashboard-3;
  }
}
.icon-dashboard-4 {
  &:before {
    content: $icon-dashboard-4;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-bell-o {
  &:before {
    content: $icon-bell-o;
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}
