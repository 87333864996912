.dSwitch.primary.active .react-switch-handle {
  background-color: $primary !important;
}
.dSwitch.primary.active .react-switch-bg {
  background-color: $primary !important;
}

.dSwitch.secondary.active .react-switch-handle {
  background-color: $secondary !important;
}
.dSwitch.secondary.active .react-switch-bg {
  background-color: $secondary !important;
}

.dSwitch.success.active .react-switch-handle {
  background-color: $success !important;
}
.dSwitch.success.active .react-switch-bg {
  background-color: $success !important;
}

.dSwitch.warning.active .react-switch-handle {
  background-color: var(--warning) !important;
}
.dSwitch.warning.active .react-switch-bg {
  background-color: var(--warning) !important;
}

.dSwitch.danger.active .react-switch-handle {
  background-color: var(--danger) !important;
}
.dSwitch.danger.active .react-switch-bg {
  background-color: var(--danger) !important;
}

.dSwitch.info.active .react-switch-handle {
  background-color: var(--info) !important;
}
.dSwitch.info.active .react-switch-bg {
  background-color: var(--info) !important;
}

.dSwitch.dark.active .react-switch-handle {
  background-color: var(--dark) !important;
}
.dSwitch.dark.active .react-switch-bg {
  background-color: var(--dark) !important;
}

.dSwitch.light.active .react-switch-handle {
  background-color: var(--light) !important;
}
.dSwitch.light.active .react-switch-bg {
  background-color: var(--light) !important;
}
