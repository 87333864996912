.datepicker-input.outline {
  border: 1px solid hsl(0, 0%, 80%);
  box-shadow: none;
  padding: .3rem 0.5rem;
  border-radius: 4px;
  text-align: center;
}
.datepicker-input.standard {
  border: none;
  border-bottom: 1px solid hsl(0, 0%, 80%);
  box-shadow: none;
  padding: .3rem 0.5rem;
  border-radius: 0;
  text-align: center;
}

