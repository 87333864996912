.dRadioWrapper {
  margin-right: 23px;
}

.dRadioWrapper input:not(:checked),
.dRadioWrapper input:checked {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.dRadioWrapper input[type="radio"]:not(:checked) + label,
.dRadioWrapper input[type="radio"]:checked + label,
.dRadioWrapper input[type="radio"]:not(:checked) + label,
.dRadioWrapper input[type="radio"]:checked + label {
  position: relative;
  display: inline-block;
  height: 1.5625rem;
  padding-left: 35px;
  line-height: 1.5625rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: .28s ease;
  transition: .28s ease;

  margin-bottom: 0;

}

.dRadioWrapper label[type="radio"]:not(:checked) + label:before,
.dRadioWrapper label[type="radio"]:not(:checked) + label:after,
.dRadioWrapper label[type="radio"]:not(:checked) + label:before,
.dRadioWrapper label[type="radio"]:not(:checked) + label:after {
  border-radius: 50%;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.dRadioWrapper input[type="radio"]+label:before,
.dRadioWrapper input[type="radio"]+label:after {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 16px;
  height: 16px;
  margin: 4px;
  content: "";
  -webkit-transition: .28s ease;
  transition: .28s ease;
  border: 2px solid #5a5a5a;
  border-radius: 50%;
}

.rtl .dRadioWrapper input[type="radio"]+label:before,
.rtl .dRadioWrapper input[type="radio"]+label:after {
  right: -25px;
}

.ltr .dRadioWrapper input[type="radio"]+label:before,
.ltr .dRadioWrapper input[type="radio"]+label:after {
  left: 0;
}

.dRadioWrapper input[type="radio"]:not(:checked)+label:after{
  -webkit-transform: scale(0);
  transform: scale(0);
  border: 2px solid #5a5a5a;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 16px;
  height: 16px;
  margin: 4px;
  content: "";
  -webkit-transition: .28s ease;
  transition: .28s ease;
}

.rtl .dRadioWrapper input[type="radio"]:not(:checked)+label:after{
  right: -25px;
}

.ltr .dRadioWrapper input[type="radio"]:not(:checked)+label:after {
  left: 0;
}

.dRadioWrapper.primary input[type="radio"]:checked+label:after,
.dRadioWrapper.primary input[type="radio"].with-gap:checked+label:after{
  background-color: $primary;
  border: 2px;
}

.dRadioWrapper.secondary input[type="radio"]:checked+label:after,
.dRadioWrapper.secondary input[type="radio"].with-gap:checked+label:after{
  background-color: $secondary;
  border: 2px;
}

.dRadioWrapper.success input[type="radio"]:checked+label:after,
.dRadioWrapper.success input[type="radio"].with-gap:checked+label:after{
  background-color: $success;
  border: 2px;
}

.dRadioWrapper.warning input[type="radio"]:checked+label:after,
.dRadioWrapper.warning input[type="radio"].with-gap:checked+label:after{
  background-color: var(--warning);
  border: 2px;
}

.dRadioWrapper.danger input[type="radio"]:checked+label:after,
.dRadioWrapper.danger input[type="radio"].with-gap:checked+label:after{
  background-color: var(--danger);
  border: 2px;
}

.dRadioWrapper.info input[type="radio"]:checked+label:after,
.dRadioWrapper.info input[type="radio"].with-gap:checked+label:after{
  background-color: var(--info);
  border: 2px;
}

.dRadioWrapper.dark input[type="radio"]:checked+label:after,
.dRadioWrapper.dark input[type="radio"].with-gap:checked+label:after{
  background-color: var(--dark);
  border: 2px;
}

.dRadioWrapper.light input[type="radio"]:checked+label:after,
.dRadioWrapper.light input[type="radio"].with-gap:checked+label:after{
  background-color: var(--light);
  border: 2px;
}
