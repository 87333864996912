@import url(//fonts.googleapis.com/css?family=Roboto:400italic,700italic,400,700);
// Font Variables
$roboto: 'Roboto', serif;




$gold: #d2ab67;
$navi: #26247b;
$darkblue: #343951;
$white: #fff;
$silver: #bfc2d1;
$green: #1abb9c;

$prime: #ffffff;
$none: transparent;
$light: #ced4da;
$dark: #000;

// Colors
$color-primary: $navi;
$primary: $gold;
$secondary: $darkblue;
$success: #007a5b;
$danger: #e01e5b;
$info: #1264a4;
$warning: #ecb22f;

// color configurations
$background: #f0f3f6;
$sidebar-background: #3f4d67;
$sidebar-color: $white;
$sidebar-indicator: lighten($sidebar-background, 60%);
$border: #ddd;
$input-placeholder-color: #ddd;
$pending-box: #ffe599;

// Themes
$themes: (
  light: (
    'white': #ffffff,
    'black': #000000,
    'primary': #2a3f54,
    'secondary': #1abb9c,
    'blue01': #007bff,
    'page-background': $background,
  ),
  dark: (
    'white': #ffffff,
    'black': #000000,
    'primary': #2a3f54,
    'secondary': #1abb9c,
    'blue01': #007bff,
    'page-background': $background,
  ),
);

// Sizes
$width-sidebar: 200px;
$height-header: 55px;
$height-header-mobile: 45px;
$width-tooltip: 300px;

// Icons
$icomoon-font-family: 'custom-icons' !default;
$icomoon-font-path: './fonts/font-icon' !default;

$icon-at: '\e923';
$icon-calendar1: '\e924';
$icon-mobile-phone: '\e925';
$icon-Shape: '\e926';
$icon-tag: '\e901';
$icon-new-1: '\e910';
$icon-new-2: '\e903';
$icon-mark: '\e908';
$icon-info: '\e921';
$icon-refund: '\e922';
$icon-close: '\e905';
$icon-diamond: '\e907';
$icon-calendar: '\e906';
$icon-star: '\e90a';
$icon-storage: '\e90b';
$icon-support: '\e90c';
$icon-user: '\e90d';
$icon-www: '\e90e';
$icon-minus: '\e90f';
$icon-order: '\e911';
$icon-password: '\e912';
$icon-pencil: '\e913';
$icon-plus-1: '\e914';
$icon-plus-2: '\e915';
$icon-plus-3: '\e916';
$icon-product: '\e917';
$icon-protest: '\e918';
$icon-bill-1: '\e920';
$icon-bill-2: '\e919';
$icon-bin: '\e91a';
$icon-gear: '\e91b';
$icon-dashboard-1: '\e91c';
$icon-dashboard-2: '\e91d';
$icon-dashboard-3: '\e91e';
$icon-dashboard-4: '\e91f';
$icon-bookmark: '\e900';
$icon-menu: '\e909';
$icon-search: '\e904';
$icon-check: '\e902';
$icon-bell-o: '\f0a2';
$icon-angle-left: '\f104';
$icon-angle-right: '\f105';
$icon-angle-down: '\f107';

$content-margin: 4.5rem;
