.public-information-page {
  .avatar-box {
    margin-bottom: 3rem;
  }
  .file-label {
    margin-top: 1rem;
    color: #757575;
  }

  .uploadBtn {
    bottom: -1.5rem;
    width: 150px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .profile-picture {
    border-radius: 5px;
    border: 1px solid $border;
  }

  .apply-btn-wrapper {
    display: flex;
    align-items: end;
    justify-content: flex-end;
  }

  .menu-box {
    border: 1px solid $border;
    border-radius: 5px;
    padding: 1rem 2rem;
  }
  .uploadBtn {
    width: 100px;
  }

  .text-info {
    text-decoration: underline;
    font-weight: bold;
  }

  .viewBtn {
    &:hover, &:active,&:disabled {
     color: $secondary !important;
      background-color: $white;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  .deleteBtn {
    &:hover, &:active, &:disabled {
      color: $danger !important;
      background-color: $white !important;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.rtl .public-information-page {
  .avatar-box {
    direction: ltr;
  }
  .file-label {
    text-align: left;
  }
}
.ltr .public-information-page {
  .avatar-box {
    direction: rtl;
  }
  .file-label {
    text-align: right;
  }
}
