button {
  &.btn {
    &:hover,
    &:focus,
    &:active,
    &:disabled {
      color: white;
    }

    &.btn-submit {
      background-color: $primary;
      color: white;
      border: 0 $primary;
      padding: 15px;
      border-radius: 10px;
      font-size: 16px;
      cursor: pointer;
      margin: 10px;
    }
    &.btn-submit:hover {
      background-color: $primary;
      border-color: $primary;
    }
    &.btn-submit:focus {
      background-color: $primary;
      border-color: $primary;
    }
    &.btn-submit:active:focus {
      background-color: $primary;
      border-color: $primary;
    }

    &.btn-cancel {
      background-color: $primary;
      color: white;
      border: 0 $primary;
      padding: 15px;
      border-radius: 10px;
      font-size: 16px;
      cursor: pointer;
      margin: 10px;
    }
    &.btn-cancel:hover {
      background-color: $primary;
      border-color: $primary;
    }
    &.btn-cancel:focus {
      background-color: $primary;
      border-color: $primary;
    }
    &.btn-cancel:active:focus {
      background-color: $primary;
      border-color: $primary;
    }
  }
}
