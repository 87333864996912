.dropdown button,
.dropleft  button ,
.dropright  button,
.dropup  button {
  border-radius: 4px;
}
.dropdown.round button,
.dropleft.round button,
.dropright.round button,
.dropup.round button {
  border-radius: 50px;
}

.dropdown.lg button {
  padding: .5rem 1rem;
  font-size: 1.015625rem;
}
.dropdown.md button {
  padding: .3rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
}
.dropdown.sm button {
  padding: 0 0.5rem;
  font-size: 0.8rem;
  line-height: unset;
}

.show>.btn-primary.dropdown-toggle {
  background-color: darken($primary, 10%);
  border-color: $primary;
}
