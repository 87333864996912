.en,
.en-US {
  font: 14px 'Roboto', IRANSans, Tahoma, verdana, Geneva, sans-serif !important;
  width: 100%;
  @include media-breakpoint-down(sm) {
    font-size: 12px !important;
  }
  .MuiTypography,
  .MuiInput-root,
  .MuiTableSortLabel-root,
  .MuiTableCell-root,
  .MuiMenuItem-root,
  .MuiInputBase-root,
  .MuiTypography-caption,
  .MuiTypography-root,
  .Tooltip-Tip,
  .react-confirm-alert-body,
  .Toastify__toast-body {
    font-family: 'Roboto', IRANSans, Tahoma, verdana, Geneva, sans-serif;
  }
}

.ar,
.ar-AE,
.fa,
.fa-IR {
  font: 14px IRANSans, Tahoma, serif !important;
  direction: rtl;
  width: 100%;
  text-align: right;
  .MuiTypography,
  .MuiInput-root,
  .MuiTableSortLabel-root,
  .MuiTableCell-root,
  .MuiMenuItem-root,
  .MuiInputBase-root,
  .MuiTypography-caption,
  .MuiTypography-root,
  .Tooltip-Tip,
  .react-confirm-alert-body,
  .Toastify__toast-body {
    font-family: IRANSans, Tahoma, serif;
    direction: rtl;
  }
}
