.dSelectBoxWrapper input{
  opacity: 0 !important;
}
.dSelectBoxWrapper.standard div[class$="-control"],
.dSelectBoxWrapper.standard div[class*="-control "] {
  border: none;
  border-bottom: 1px solid hsl(0,0%,80%);
  border-radius: 0;
}
.dSelectBoxWrapper.standard div[class$="-indicatorContainer"],
.dSelectBoxWrapper.standard div[class*="-indicatorContainer "] {
  padding-top: 2px;
  padding-bottom: 2px;
}
