.gallery-page {
  .galleryImgBox {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    position: relative;
    max-width: 7rem;
    max-height: 7rem;
  }

  .galleryRow img {
    width: 100%;
    max-height: calc(7rem - 10px);
  }

  .galleryImgBox .delete-logo {
    visibility: hidden;
  }

  .galleryImgBox:hover .delete-logo {
    visibility: visible;
  }

  .galleryContainer {
    margin-top: 1rem;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px dotted #ced4da;
      .col-md-4 {
        width: 29.9%;
      }
      .col-md-8 {
        width: 69.9%;
      }
    }
  }

  .delete-logo {
    position: absolute;
    left: 1px;
    top: 0;
    color: red;
    cursor: pointer;
  }

  .add-gallery {
    border: 1px dashed;
    padding: 1rem 1.5rem !important;
    font-size: 1.5rem;
    margin-top: 1.25rem;
    color: #bbb;
    &:hover {
      font-weight: bold;
      color: #6c6b6b;
      background-color: inherit !important;
    }
  }
}

