.pagination {
  display: flex;
  align-items: center;
  direction: ltr;

  .page-link {
    position: relative;
    display: block;
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -$pagination-border-width;
    line-height: $pagination-line-height;
    color: $pagination-color;
    background-color: transparent;
    border: 0;

    &:hover {
      // z-index: 2;
      color: $color-primary;
      text-decoration: none;
      background-color: transparent;
      border-color: transparent;
    }

    &:focus {
      // z-index: 2;
      outline: none;
      box-shadow: none;
    }

    // Opinionated: add "hand" cursor to non-disabled .page-link elements
    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  .page-item {
    &:first-child {
      .page-link {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }

    &:last-child {
      .page-link {
        @include border-right-radius($border-radius);
      }
    }

    &.active .page-link {
      // z-index: 1;
      color: $sidebar-background;
      background-color: transparent;
      border-color: transparent;
    }

    &.disabled .page-link {
      color: $pagination-disabled-color;
      pointer-events: none;
      // Opinionated: remove the "hand" cursor set previously for .page-link
      cursor: auto;
      background-color: transparent;
    }
  }

  .icon {
    border: 1px solid gray;
    border-radius: 5px;
    background-color: white;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
