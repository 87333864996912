.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
    + .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      padding-right: 0;
      height: auto;
      &:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        left: 4px;
        top: 0;
        bottom: 4px;
        border: 0;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      &.round {
        border-radius: 34px;
        &:before {
          border-radius: 50%;
        }
      }
    }
    &:checked {
      + .slider {
        background-color: #2196f3;
        &:before {
          -webkit-transform: translateX(16px);
          -ms-transform: translateX(16px);
          transform: translateX(16px);
        }
      }
    }
    &:focus {
      + .slider {
        box-shadow: 0 0 1px #2196f3;
      }
    }
  }
}
