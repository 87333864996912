.icon-alarm {
  width: 50px;
  height: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 5px -3px #5a5a5a;
  margin-left: 10px;

  i {
    color: #9b9da7;
    font-size: 25px;
  }
}

.profile {
  background-color: white;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 1px 5px -3px #5a5a5a;

  @include media-breakpoint-down(sm) {
    background-color: transparent;
    box-shadow: none;
  }
  .dropdown-list {
    display: none;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: auto;
    border-radius: 10px;
    box-shadow: 0 1px 8px -4px grey;
    padding: 5px 15px;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      right: 0;
      left: auto;
    }

    &:hover {
      display: block;
    }

    .dropdown-item {
      padding: 8px;
      color: black;
      border-radius: 10px;

      &:hover {
        background-color: #ffffff;
      }

      &:active {
        background-color: #ffffff !important;
      }
    }

    hr {
      border-top: 1px solid #eee;
      margin: 8px 0;
    }
  }

  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all ease 0.3s;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      padding: 0;
      justify-content: center;
    }

    &::after {
      display: none;
    }

    &:hover + .dropdown-list {
      display: block;
    }

    .image-user {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-left: 10px;

      @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
        border: 3px solid white;
        margin: 0;
      }
    }

    .description {
      display: block;
      width: auto;
      max-width: none;
      padding: 0 0 0 30px;
      position: relative;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      p {
        margin-bottom: 0;
      }

      .name {
        color: black;
      }

      .work {
        color: #9b9da7;
        font-size: 12px;
        margin-top: 2px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -40%);
        color: inherit;
        font-size: 18px;
        line-height: 20px;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  .dropdown-menu {
    width: calc(100% - 5px);
    border-radius: 0;
  }

  &.show {
    @include themify() {
      background: rgba(themed('primary'), 0.1s);
    }
  }
}

.messages {
  @include media-breakpoint-down(sm) {
    background-color: transparent;
    box-shadow: none;
    width: 120px;
  }
  .dropdown-list {
    display: none;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: auto;
    margin-top: 1px;
    border-radius: 10px;
    box-shadow: 0 1px 8px -4px grey;
    z-index: 10;
    min-width: 200px;

    @include media-breakpoint-down(xs) {
      left: auto;
      right: 0;
    }

    &:hover {
      display: block;
    }

    .dropdown-item {
      padding: 8px 15px;
      color: black;
      border-radius: 10px;
      font-size: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);

      &:hover {
        background-color: #ffffff;
      }

      &:active {
        background-color: #ffffff !important;
      }

      &.moreBtn {
        color: $primary;
        text-align: center;
      }
    }
  }

  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all ease 0.3s;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      padding: 0;
      justify-content: center;
    }

    &::after {
      display: none;
    }

    &:hover + .dropdown-list {
      display: block;
    }

    .image-user {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid gray;
      margin-left: 10px;

      @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
        border: 3px solid white;
        margin: 0;
      }
    }

    .description {
      display: block;
      width: auto;
      max-width: none;
      padding: 0 0 0 30px;
      position: relative;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      p {
        margin-bottom: 0;
      }

      .name {
        color: black;
      }

      .work {
        color: #9b9da7;
        font-size: 12px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      i {
        position: absolute;
        left: 0;
        top: 45%;
        transform: translate(0, -40%);
        color: inherit;
        font-size: 18px;
        line-height: 20px;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  .dropdown-menu {
    width: calc(100% - 5px);
    border-radius: 0;
  }

  &.show {
    @include themify() {
      background: rgba(themed('primary'), 0.1s);
    }
  }
}

.section-two {
  &__item1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &__box {
      flex-basis: 45%;
      margin: 5px;
      background-color: white;
      border-radius: 15px;
      padding: 15px;

      i {
        font-size: 40px;
      }

      .title {
        font-size: 18px;
      }

      .description {
        color: #9b9da7;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }
  }

  &__item3 {
    display: flex;
    flex-wrap: wrap;

    &__box {
      flex-basis: 46%;
      margin: 5px 3px;
      background-color: white;
      border-radius: 15px;
      padding: 10px 5px;
      text-align: center;
      i {
        font-size: 30px;
      }

      &.active {
        background-color: rgb(210, 171, 103);

        .title {
          color: white;
        }
      }

      .title {
        color: #9b9da7;
        font-size: 11px;
        text-align: center;
        font-weight: 500;
        margin-top: 10px;
      }
    }
  }
}

.section-three {
  &__item1 {
    background-color: white;
    border-radius: 15px;
    padding: 15px;

    .title {
      font-size: 18px;
    }

    .table {
      background-color: white;
      text-align: center;

      thead {
        background-color: #f7f8f9;

        th {
          padding: 5px;
          text-align: center;
        }
      }

      td {
        font-size: 15px;
        padding: 15px 0.75rem;
      }

      .icon {
        width: 25px;
        height: 25px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        color: white;
      }

      .icon-green {
        background-color: rgb(61, 213, 152);
      }

      .icon-red {
        background-color: rgb(252, 90, 90);
      }

      .icon-yellow {
        background-color: rgb(255, 151, 74);
      }

      .number-mobile {
        color: $primary;
      }
    }
  }

  &__item2 {
    background-color: white;
    border-radius: 15px;
    padding: 15px;

    .box-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 25px;
        margin-left: 10px;
      }

      p {
        margin-bottom: 0;
      }

      .title {
        font-weight: bold;
        font-size: 14px;
      }

      .description {
        font-size: 12px;
        color: #9b9da7;
      }

      .btn-signup {
        border-radius: 25px;
        border: 1px solid $primary;
        color: $primary;
        padding: 4px 15px;
        font-size: 14px;
        background: transparent;
        text-decoration: none;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .image-profile {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 10px;
    }

    .name {
      font-size: 13px;
      font-weight: bold;
    }

    .number-mobile {
      color: $primary;
      font-size: 13px;
    }

    .table {
      background-color: white;

      thead {
        background-color: #f7f8f9;

        th {
          padding: 5px;
        }
      }

      td {
        font-size: 15px;
        padding: 15px 0.75rem;

        .icons {
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          margin-top: 10px;
        }
      }
    }
  }
}

// chart
.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  // max-width: 800px;
  margin: 6px auto 0;
  height: 285px;
}

#container {
  border-radius: 15px;
  height: 280px;
}

.highcharts-root {
  //font-family: IRANSans !important;
}

.highcharts-data-table table {
  // font-family    : IRANSans;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
