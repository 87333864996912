.deals-page {
  .card.bg-warning {
    background-color: $pending-box !important;
    //.dInputWrapper label {
    //  background-color: #fdff9400 !important;
    //}
    .dInputWrapper > label.active {
      border-radius: 5px;
    }
  }
  [contenteditable].form-control[disabled], [contenteditable].form-control[disabled]::placeholder, [type=email].form-control[disabled], [type=email].form-control[disabled]::placeholder, [type=password].form-control[disabled], [type=password].form-control[disabled]::placeholder, [type=tel].form-control[disabled], [type=tel].form-control[disabled]::placeholder, [type=text].form-control[disabled], [type=text].form-control[disabled]::placeholder, input.form-control[disabled], input.form-control[disabled]::placeholder, input[type=email][disabled], input[type=email][disabled]::placeholder, input[type=number][disabled], input[type=number][disabled]::placeholder, input[type=password][disabled], input[type=password][disabled]::placeholder, input[type=text][disabled], input[type=text][disabled]::placeholder, textarea.form-control[disabled], textarea.form-control[disabled]::placeholder, textarea[disabled], textarea[disabled]::placeholder {
    color: #000;
  }

  .btn-success {
    color: $prime;
  }
  .danger-lighter {
    background-color: lighten($danger, 30%);
    border: lighten($danger, 35%);
    color: $dark !important;
  }

  input, textarea {
    background-color: $white !important;
  }

  .show-panel {
    border: unset !important;
    background-color: inherit !important;
  }

  .dInputWrapper > label:not(.active) {
    top: -7px
  }
  .dInputWrapper input {
    padding: 0.3rem;
  }

  .deal-table-wrapper {
    margin: 1rem -1rem;
  }

  .waiting-label {
    font-size: 0.8rem;
    color: $danger !important;
  }

  .accepted-label {
    font-size: 0.8rem;
    color: $success !important;
    font-weight: bold;
  }

  button {
    &.status-label {
      box-shadow: none !important;
      cursor: auto !important;
      white-space: normal;
      &.btn-info {
        background-color: $info;
      }
      &.btn-success:hover{
        background-color: $success;
      }
    }
  }

  .MuiPaper-root .MuiTable-root .MuiTableCell-root .icons {
    justify-content: flex-end !important;
  }
}

.addDealWrapper {
  .week-box {
    border: 2px solid $border;
    padding: 1rem;
  }
  .dealTimePicker{
     width: 7rem;
  }
  .invalid-feedback.timePickerFeedback {
    display: block;
    width: 8rem;
    text-align: center;
  }
}

.rtl .account-page {
  .avatar-box {
    direction: ltr;
  }
}
.ltr .account-page {
  .avatar-box {
    direction: rtl;
  }
}

.invalid-feedback {
  display: block;
}
